/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../nav-item/nav-item.component.ngfactory";
import * as i2 from "../nav-item/nav-item.component";
import * as i3 from "@angular/common";
import * as i4 from "./nav-menu.component";
var styles_NavMenuComponent = [];
var RenderType_NavMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavMenuComponent, data: {} });
export { RenderType_NavMenuComponent as RenderType_NavMenuComponent };
function View_NavMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-nav-item", [], null, null, null, i1.View_NavItemComponent_0, i1.RenderType_NavItemComponent)), i0.ɵdid(1, 573440, null, 0, i2.NavItemComponent, [], { isWide: [0, "isWide"], node: [1, "node"], selectedNodes: [2, "selectedNodes"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isWide; var currVal_1 = _v.context.$implicit; var currVal_2 = ((_co.currentNode == null) ? null : _co.currentNode.nodes); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_NavMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavMenuComponent_1)), i0.ɵdid(1, 802816, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredNodes; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NavMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-nav-menu", [], null, null, null, View_NavMenuComponent_0, RenderType_NavMenuComponent)), i0.ɵdid(1, 49152, null, 0, i4.NavMenuComponent, [], null, null)], null, null); }
var NavMenuComponentNgFactory = i0.ɵccf("aio-nav-menu", i4.NavMenuComponent, View_NavMenuComponent_Host_0, { currentNode: "currentNode", isWide: "isWide", nodes: "nodes" }, {}, []);
export { NavMenuComponentNgFactory as NavMenuComponentNgFactory };
