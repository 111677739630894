/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./search-results.component";
var styles_SearchResultsComponent = [];
var RenderType_SearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchResultsComponent, data: {} });
export { RenderType_SearchResultsComponent as RenderType_SearchResultsComponent };
function View_SearchResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_SearchResultsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "symbol ", _v.parent.context.$implicit.type, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SearchResultsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "search-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "search-result-item"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onResultSelected(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchResultsComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, null, ["", " "]))], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.name === "api"); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.path, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
function View_SearchResultsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "symbol ", _v.parent.context.$implicit.type, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SearchResultsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "search-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "search-result-item"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onResultSelected(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchResultsComponent_7)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, null, ["", " "]))], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.name === "api"); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.path, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
function View_SearchResultsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "search-area"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", " (", ")"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "ul", [["class", "priority-pages"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchResultsComponent_4)), i0.ɵdid(5, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchResultsComponent_6)), i0.ɵdid(8, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.priorityPages; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.pages; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = (_v.context.$implicit.pages.length + _v.context.$implicit.priorityPages.length); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_SearchResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Search Results"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchResultsComponent_3)), i0.ɵdid(3, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchAreas; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SearchResultsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notFoundMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_SearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchResultsComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["searchResults", 2]], null, 0, null, View_SearchResultsComponent_2)), (_l()(), i0.ɵand(0, [["notFound", 2]], null, 0, null, View_SearchResultsComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchAreas.length; var currVal_1 = i0.ɵnov(_v, 3); var currVal_2 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-search-results", [], null, null, null, View_SearchResultsComponent_0, RenderType_SearchResultsComponent)), i0.ɵdid(1, 573440, null, 0, i2.SearchResultsComponent, [], null, null)], null, null); }
var SearchResultsComponentNgFactory = i0.ɵccf("aio-search-results", i2.SearchResultsComponent, View_SearchResultsComponent_Host_0, { searchResults: "searchResults" }, { resultSelected: "resultSelected" }, []);
export { SearchResultsComponentNgFactory as SearchResultsComponentNgFactory };
