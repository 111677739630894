/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "./notification.component";
import * as i8 from "../../shared/window";
import * as i9 from "../../shared/current-date";
var styles_NotificationComponent = [];
var RenderType_NotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationComponent, data: { "animation": [{ type: 7, name: "hideAnimation", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { height: 0 }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: { type: 4, styles: null, timings: 250 }, options: null }], options: {} }] } });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
export function View_NotificationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "content"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "icon mat-icon"], ["role", "img"]], [[1, "aria-label", 0], [2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(2, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "action-button"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "close-button"], ["mat-icon-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(8, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.Platform, i6.FocusMonitor], null, null), (_l()(), i0.ɵeld(9, 0, null, 0, 1, "mat-icon", [["aria-label", "Dismiss notification"], ["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(10, 638976, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.icon; _ck(_v, 2, 0, currVal_3); var currVal_7 = "close"; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.actionUrl, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.iconLabel; var currVal_2 = i0.ɵnov(_v, 2).inline; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = _co.buttonText; _ck(_v, 6, 0, currVal_4); var currVal_5 = (i0.ɵnov(_v, 8).disabled || null); _ck(_v, 7, 0, currVal_5); var currVal_6 = i0.ɵnov(_v, 10).inline; _ck(_v, 9, 0, currVal_6); }); }
export function View_NotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aio-notification", [], [[40, "@hideAnimation", 0]], null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i0.ɵdid(1, 114688, null, 0, i7.NotificationComponent, [i8.WindowToken, i9.CurrentDateToken], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).showNotification; _ck(_v, 0, 0, currVal_0); }); }
var NotificationComponentNgFactory = i0.ɵccf("aio-notification", i7.NotificationComponent, View_NotificationComponent_Host_0, { icon: "icon", iconLabel: "iconLabel", buttonText: "buttonText", actionUrl: "actionUrl", notificationId: "notificationId", expirationDate: "expirationDate" }, { dismissed: "dismissed" }, ["*"]);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
